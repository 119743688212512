import React, { createContext, useState, useEffect, useRef } from "react";
import keycloak from '../services/keycloak';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [keycloakInstance, setKeycloak] = useState(null);
  const [token, setToken] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [authenticated, setAuthenticated] = useState(false);
  const [acquirerData, setAcquirerData] = useState({
    acquirerId: null,
    acquirerName: null,
    acquirerRol: null,
    isoID: null,
    currency_code: null,
  });

  const isKeycloakInitialized = useRef(false);  

  useEffect(() => {
    if (!isKeycloakInitialized.current) {
      keycloak.init({ onLoad: "login-required" }).then((auth) => {
        setAuthenticated(auth);
        if (auth) {
          setKeycloak(keycloak);
          setToken(keycloak.token);

          const decodedToken = jwtDecode(keycloak.token);

          setAcquirerData({
            acquirerId: decodedToken["acquirer-id-data"],  
            acquirerName: decodedToken["acquirer-name-data"],  
            acquirerRol: decodedToken["acquirer-rol-data"]  === "admin" ? -99 : 1,
            isoID: decodedToken["acquirer-id-iso-data"], 
            currency_code: decodedToken["acquirer-currency-data"], 
          });

          keycloak.loadUserInfo().then(userInfo => {
            setUserInfo(userInfo);
          });
        }
      }).catch(error => {
        console.error("Error initializing Keycloak: ", error);
      });

      isKeycloakInitialized.current = true; 
    }
  }, []); 

  const logout = () => {
    if (keycloakInstance) {
      keycloakInstance.logout()
        .then((success) => {
          console.log("User logout success", success);
        })
        .catch((error) => {
          console.log("User logout error", error);
        });
  
      setAuthenticated(false);
      setToken(null);  
      setUserInfo({});
      setAcquirerData({
        acquirerId: null,
        acquirerName: null,
        acquirerRol: null,
        isoID: null,
        currency_code: null,
      });
    }
  };  

  return (
    <AuthContext.Provider value={{ keycloak: keycloakInstance, token, userInfo, authenticated, acquirerData, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
