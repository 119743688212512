import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ButtonGroup,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';

import AuthContext from "../../context/AuthContext";
import { fetchData } from "../../services/apiService";
import { getTerminalDetails } from "../../services/terminal/terminalService";
import { deleteTerminal } from "../../services/terminal/deleteTerminalService";
import TerminalDetails from "../TerminalDetails";

export default function MerchantSearch({ setSnackOpen, setMessageOpen, setSeverity }) {
  const { acquirerData, token } = useContext(AuthContext);
  const [merchants, setMerchants] = useState([]);
  const [loadingMerchants, setLoadingMerchants] = useState(false);
  const [expandedMerchant, setExpandedMerchant] = useState(null);
  const [loadingTerminals, setLoadingTerminals] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [terminalDetails, setTerminalDetails] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [terminalToDelete, setTerminalToDelete] = useState(null);
  const [expandedTerminal, setExpandedTerminal] = useState(null);
  const [accordionExpanded, setAccordionExpanded] = useState(true); 
  const [isEditing, setIsEditing] = useState(false);

  const handleAccordionToggle = () => {
    setAccordionExpanded(prev => !prev); // Alterna o estado do Accordion
  };

  // Função para buscar os merchants
  const handleSearchMerchants = async () => {
    setLoadingMerchants(true);
    setMerchants([]);
    setExpandedMerchant(null);

    try {
      const endpoint = "/merchant-list";
      const data = {
        version: "04.09.01",
        acquirerID: acquirerData.acquirerId,
      };
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        acquirerName: acquirerData.acquirerName,
        acquirerID: acquirerData.acquirerId,
      };

      const response = await fetchData({ endpoint, data, headers, token });
      setMerchants(response.data?.merchant || []);

      if (response.data?.merchant?.length) {
        setSnackOpen(true);
        setMessageOpen("Merchants loaded successfully");
        setSeverity("success");
      } else {
        setSnackOpen(true);
        setMessageOpen("No merchants found");
        setSeverity("info");
      }
    } catch (error) {
      console.error(error);
      setSnackOpen(true);
      setMessageOpen("Error while fetching merchants");
      setSeverity("error");
    } finally {
      setLoadingMerchants(false);
    }
  };

  // Função para buscar os terminais do merchant selecionado
  const handleExpandMerchant = async (merchantID) => {
    if (expandedMerchant === merchantID) {
      setExpandedMerchant(null);
      return;
    }

    setLoadingTerminals(true);

    try {
      const endpoint = "/terminals-list";
      const data = {
        version: "04.09.01",
        acquirer: {
          merchantID: merchantID,
        },
      };
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        acquirerName: acquirerData.acquirerName,
        acquirerID: acquirerData.acquirerId,
      };

      const response = await fetchData({ endpoint, data, headers, token });

      setMerchants((prevMerchants) =>
        prevMerchants.map((merchant) =>
          merchant.merchantID === merchantID
            ? { ...merchant, terminals: response.data?.acquirer?.terminals || [] }
            : merchant
        )
      );

      setExpandedMerchant(merchantID);
    } catch (error) {
      console.error(error);
      setSnackOpen(true);
      setMessageOpen("Error while fetching terminals");
      setSeverity("error");
    } finally {
      setLoadingTerminals(false);
    }
  };

  const handleView = async (terminal, merchantID) => {
    setLoadingDetails(true);
    setIsEditing(false);
    try {
      const details = await getTerminalDetails(terminal.acquirerTerminalID, token, acquirerData, merchantID);
      setTerminalDetails(details);
      setExpandedTerminal(terminal.acquirerTerminalID); 
    } catch (error) {
      setSnackOpen(true);
      setMessageOpen("Error fetching terminal details");
      setSeverity("error");
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleEdit = async (terminal, merchantID) => {
    setLoadingDetails(true);
    setIsEditing(true);
    try {
      const details = await getTerminalDetails(terminal.acquirerTerminalID, token, acquirerData, merchantID);
      setTerminalDetails(details);
      setExpandedTerminal(terminal.acquirerTerminalID); 
    } catch (error) {
      setSnackOpen(true);
      setMessageOpen("Error fetching terminal details");
      setSeverity("error");
    } finally {
      setLoadingDetails(false);
    }
  };
  

  const confirmDelete = (terminal, merchantID) => {
    setTerminalToDelete({ ...terminal, merchantID });
    setDialogOpen(true);
  };

  const handleDelete = async () => {
    setLoadingDetails(true);
    try {
      await deleteTerminal(terminalToDelete.acquirerTerminalID, token, acquirerData, terminalToDelete.merchantID);
      setSnackOpen(true);
      setMessageOpen("Terminal deleted successfully");
      setSeverity("success");

      setMerchants((prevMerchants) =>
        prevMerchants.map((merchant) =>
          merchant.merchantID === expandedMerchant
            ? {
                ...merchant,
                terminals: merchant.terminals.filter(
                  (item) => item.acquirerTerminalID !== terminalToDelete.acquirerTerminalID
                ),
              }
            : merchant
        )
      );
    } catch (error) {
      setSnackOpen(true);
      setMessageOpen("Error deleting terminal");
      setSeverity("error");
    } finally {
      setLoadingDetails(false);
      setDialogOpen(false);
      setTerminalToDelete(null);
    }
  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="center" mb={2}>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSearchMerchants}
            disabled={loadingMerchants}
          >
            {loadingMerchants ? "Loading Merchants..." : "Load Merchants"}
          </Button>
        </Grid>
      </Grid>

      {merchants.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Merchant Name</TableCell>
                <TableCell>Merchant Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {merchants.map((merchant, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{merchant.merchantID}</TableCell>
                    <TableCell>{merchant.merchantName || "Unnamed Merchant"}</TableCell>
                    <TableCell>{merchant.merchantStatus}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleExpandMerchant(merchant.merchantID)}
                      >
                        {expandedMerchant === merchant.merchantID ? "Hide Terminals" : "View Terminals"}
                      </Button>
                    </TableCell>
                  </TableRow>
                  {expandedMerchant === merchant.merchantID && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Accordion expanded>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Terminals</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {loadingTerminals ? (
                              <CircularProgress />
                            ) : merchant.terminals && merchant.terminals.length > 0 ? (
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Terminal ID</TableCell>
                                    <TableCell>Terminal Type</TableCell>
                                    <TableCell>Terminal Status</TableCell>
                                    <TableCell>Actions</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {merchant.terminals.map((terminal, idx) => (
                                    <React.Fragment key={idx}>
                                      <TableRow>
                                        <TableCell>{terminal.acquirerTerminalID}</TableCell>
                                        <TableCell>{terminal.terminalType}</TableCell>
                                        <TableCell>{terminal.terminalStatus}</TableCell>
                                        <TableCell>
                                          <ButtonGroup>
                                            <Button
                                              color="secondary"
                                              onClick={() => handleView(terminal, merchant.merchantID)}
                                            >
                                              <VisibilityIcon /> {/* Ícone de olho */}
                                            </Button>
                                            <Button
                                              color="primary"
                                              onClick={() => handleEdit(terminal, merchant.merchantID)}
                                            >
                                              <EditIcon />
                                            </Button>
                                            <Button
                                              color="error"
                                              onClick={() => confirmDelete(terminal, merchant.merchantID)}
                                            >
                                              <DeleteIcon />
                                            </Button>
  
                                          </ButtonGroup>
                                        </TableCell>
                                      </TableRow>
                                      {expandedTerminal === terminal.acquirerTerminalID && (
                                        <TableRow>
                                          <TableCell colSpan={4}>
                                            {loadingDetails ? (
                                              <Box display="flex" justifyContent="center">
                                                <CircularProgress />
                                              </Box>
                                            ) : (
                                              terminalDetails && (
                                                <>
                                                  <Button onClick={handleAccordionToggle} variant="contained" color="primary" style={{ marginBottom: '10px' }}>
                                                    {accordionExpanded ? 'Hide Terminal' : 'Show Terminal'}
                                                  </Button>
                                                  {accordionExpanded && (
                                                    <Accordion expanded>
                                                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography>Terminal Details</Typography>
                                                      </AccordionSummary>
                                                      <AccordionDetails>
                                                        <TerminalDetails
                                                          terminalDetails={terminalDetails}
                                                          setSnackOpen={setSnackOpen}
                                                          setMessageOpen={setMessageOpen}
                                                          setSeverity={setSeverity}
                                                          disabled={!isEditing}
                                                        />
                                                      </AccordionDetails>
                                                    </Accordion>
                                                  )}
                                                </>
                                              )
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </TableBody>
                              </Table>
                            ) : (
                              <Typography>No terminals found for this merchant.</Typography>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {loadingDetails && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      )}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this terminal? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDelete(terminalToDelete.merchantID)} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
