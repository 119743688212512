import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Grid, Paper, TextField, Button, Divider, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import AuthContext from "../context/AuthContext";
import SaveIcon from '@mui/icons-material/Save';
import { enrollmentTerminal } from "../services/terminal/enrollmentTerminalService";

const TerminalDetails = ({ terminalDetails, setSnackOpen, setMessageOpen, setSeverity, disabled = false}) => {
  const [formData, setFormData] = useState(terminalDetails || {});
  const { acquirerData, token } = useContext(AuthContext);
  const [isUpdated, setIsUpdated] = useState(false); 
  const [dialogOpen, setDialogOpen] = useState(false); 
  const url = "/edit"
  
  const handleInputChange = (e) => {
    
    const { name, value } = e.target;
    setFormData((prevData) => {
      const keys = name.split(".");
      const updatedData = { ...prevData };
      let current = updatedData;

      for (let i = 0; i < keys.length - 1; i++) {
        current[keys[i]] = current[keys[i]] || {};
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;
      return updatedData;
    });
  };

  const handleSave = async () => {
    try {

      const dataTerminal = await enrollmentTerminal(url ,formData, token, acquirerData);
      setFormData(dataTerminal);
      setIsUpdated(true);

      setSnackOpen(true); 
      setMessageOpen("Data updated successfully!");
      setSeverity("success");

      setDialogOpen(true); 
    } catch (error) {
      console.error('Error saving data:', error);
      let errorMessage = "Merchant Terminal NOT saved!";

      if (error.request?.responseText) {
        try {
          const response = JSON.parse(error.request.responseText); 
          if (response.detail) {
            errorMessage += `: ${response.detail}`; 
          }
        } catch (parseError) {
          console.error("Failed to parse error.responseText:", parseError);
          errorMessage += `: ${error.request.responseText}`;
        }
      }
      setSeverity("error");
      setMessageOpen(errorMessage); 
      setSnackOpen(true); 
    }
  };

  if (!terminalDetails) return null;

  const renderField = (label, value, name, maxLength = null, options = null, date = false) => (
    <Grid item xs={12} sm={6} md={4}>
      {options ? (
        <FormControl fullWidth margin="normal">
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            labelId={`${name}-label`}
            label={label}
            name={name}
            value={value !== undefined && value !== null ? value : ""}
            onChange={handleInputChange}
            variant="outlined"
            disabled={disabled}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : date ? (
        <TextField
          fullWidth
          label={label}
          name={name}
          type="date" // Define o campo como tipo "date"
          value={value !== undefined && value !== null ? value : ""}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={disabled}
          inputProps={{ maxLength }}
        />
      ) : (
        <TextField
          fullWidth
          label={label}
          name={name}
          value={value !== undefined && value !== null ? value : ""}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={disabled}
          inputProps={{ maxLength }}
        />
      )}
    </Grid>
  );

  return (
    <Box mt={4} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      {!isUpdated && (
        <>
          <Typography variant="h5" gutterBottom>
            Terminal Details
          </Typography>
          <Paper elevation={3} sx={{ padding: 3, overflowX: 'auto' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Terminal Details
                </Typography>
                <Divider />
              </Grid>
              <Grid container spacing={2}>
                {renderField(
                  "Terminal ID",
                  formData.acquirer?.terminal?.terminals?.[0]?.terminalID,
                  "acquirer.terminal.terminals.0.terminalID",
                  16
                )}
                {renderField(
                  "Acquirer Terminal ID",
                  formData.acquirer?.terminal?.terminals?.[0]?.terminalSerial,
                  "acquirer.terminal.terminals.0.terminalSerial",
                  16
                )}
                {renderField(
                  "Terminal Type",
                  formData.acquirer?.terminal?.terminals?.[0]?.terminalType,
                  "acquirer.terminal.terminals.0.terminalType",
                  null,
                  [
                    { value: "A", label: "ATM = A" },
                    { value: "C", label: "COTS (Phone/Tablet) = C" },
                    { value: "E", label: "eCommerce = E" },
                    { value: "R", label: "ECR = R" },
                    { value: "I", label: "App = I" },
                    { value: "K", label: "Kiosk = K" },
                    { value: "M", label: "mPOS = M" },
                    { value: "P", label: "POS = P" },
                    { value: "T", label: "Teller = T" },
                    { value: "V", label: "Vending Machine = V" },
                  ]
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Acquirer Details
                </Typography>
                <Divider />
              </Grid>
              <Grid container spacing={2}>
                {renderField(
                  "Acquirer ID",
                  acquirerData?.acquirerId,
                  "acquirerData.acquirerId"
                )}
                {formData.acquirer.iso === true && (
                  <>
                    {renderField(
                      "Acquirer ISO ID",
                      formData.acquirer?.isoID,
                      "acquirer.isoID",
                      4
                    )}
                  </>
                 )}
                 {renderField(
                  "Currency Code",
                  formData.fiat?.currencyCode,
                  "fiat.currencyCode",
                  null,
                  [
                    { value: "BRL", label: "BRL" },
                    { value: "USD", label: "USD" },
                  ]
                )}
                {renderField(
                  "MCC ID",
                  formData.acquirer?.mccID,
                  "acquirer.mccID",
                  4
                )}
                {renderField(
                  "Amount Other",
                  formData.acquirer.enableAmountOther ?? false, 
                  "acquirer.enableAmountOther",
                  null,
                  [
                    { value: true, label: "True" },
                    { value: false, label: "False" },
                  ]
                )}
                {renderField(
                  "Holding",
                  formData.acquirer?.merchantHolding,
                  "acquirer.merchantHolding",
                  16
                )}
                {renderField(
                  "Holding ID",
                  formData.acquirer?.merchantHoldingID,
                  "acquirer.merchantHoldingID",
                  16
                )}
                {renderField(
                  "Group",
                  formData.acquirer?.merchantGroup,
                  "acquirer.merchantGroup",
                  16
                )}
                {renderField(
                  "Group ID",
                  formData.acquirer?.merchantGroupID,
                  "acquirer.merchantGroupID",
                  16
                )}
                {renderField(
                  "Merchant Name",
                  formData.acquirer?.merchantName,
                  "acquirer.merchantName",
                  16
                )}
                {renderField(
                  "Store",
                  formData.acquirer?.merchantStore,
                  "acquirer.merchantStore",
                  8
                )}
                {renderField(
                  "Address",
                  formData.acquirer?.merchantAddress,
                  "acquirer.merchantAddress",
                  32
                )}
                {renderField(
                  "Merchant ID",
                  formData.acquirer?.merchantID,
                  "acquirer.merchantID",
                  16
                )}
                {renderField(
                  "Neighborhood",
                  formData.acquirer?.merchantNeighborhood,
                  "acquirer.merchantNeighborhood",
                  32
                )}
                {renderField(
                  "City",
                  formData.acquirer?.merchantCity,
                  "acquirer.merchantCity",
                  32
                )}
                {renderField(
                  "State",
                  formData.acquirer?.merchantState,
                  "acquirer.merchantState",
                  32
                )}
                {renderField(
                  "Postal Code",
                  formData.acquirer?.merchantPostalCode,
                  "acquirer.merchantPostalCode",
                  16
                )}
              </Grid>
              {formData.acquirer.iso === true && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Merchant Details
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid container spacing={2}>
                    {renderField(
                      "Legal Name",
                      formData.merchant?.legal_name,
                      "merchant.legal_name",
                      16
                    )}
                    {renderField(
                      "Business Email",
                      formData.merchant?.business_email,
                      "merchant.business_email",
                      16
                    )}
                    {renderField(
                      "Business Phone",
                      formData.merchant?.business_phone,
                      "merchant.business_phone",
                      16
                    )}
                    {renderField(
                      "Business Type",
                      formData.merchant?.business_type,
                      "merchant.business_type",
                      16
                    )}
                    {renderField(
                      "Taxpayer ID",
                      formData.merchant?.taxpayer_id,
                      "merchant.taxpayer_id",
                      16
                    )}
                    {renderField(
                      "Incorporation Date",
                      formData.merchant?.incorporation_date,
                      "merchant.incorporation_date",
                      null,
                      null,
                      true 
                    )}
                    {renderField(
                      "Effective Date",
                      formData.merchant?.effective_date,
                      "merchant.effective_date",
                      null,
                      null,
                      true 
                    )}
                    {renderField(
                      "Amount TX Processing",
                      formData.merchant?.amount_tx_processing,
                      "merchant.amount_tx_processing",
                      32
                    )}
                    {renderField(
                      "Amount TX Processing Tiers",
                      formData.merchant?.amount_tx_processing_tiers,
                      "merchant.amount_tx_processing_tiers",
                      32
                    )}
                    {renderField(
                      "Percent TX Processing",
                      formData.merchant?.percent_tx_processing,
                      "merchant.percent_tx_processing",
                      32
                    )}
                    {renderField(
                      "Percent TX Processing Tiers",
                      formData.merchant?.percent_tx_processing_tiers,
                      "merchant.percent_tx_processing_tiers",
                      32
                    )}
                    {renderField(
                      "Amount Terminal Maintenance",
                      formData.merchant?.amount_terminal_maintenance,
                      "merchant.amount_terminal_maintenance",
                      32
                    )}
                    {renderField(
                      "Amount Terminal Maintenance Tiers",
                      formData.merchant?.amount_terminal_maintenance_tiers,
                      "merchant.amount_terminal_maintenance_tiers",
                      32
                    )}
                    {renderField(
                      "Terminal Maintenance Inactive",
                      formData.merchant?.terminal_maintenance_inactive,
                      "merchant.terminal_maintenance_inactive",
                      null,
                      [
                        { value: true, label: "True" },
                        { value: false, label: "False" },
                      ]
                    )}
                    {renderField(
                      "Amount Terminal Setup",
                      formData.merchant?.amount_terminal_setup,
                      32,
                      "merchant.amount_terminal_setup"
                    )}
                    {renderField(
                      "Amount Terminal Setup Tiers",
                      formData.merchant?.amount_terminal_setup_tiers,
                      "merchant.amount_terminal_setup_tiers",
                      32
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Bank Details
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid container spacing={2}>
                    {renderField("Bid", formData.bank?.bid, "bank.bid", 8)}
                    {renderField("SWIFT Code", formData.bank?.swift_code, "bank.swift_code", 16)}
                    {renderField("Account Number", formData.bank?.account_number, "bank.account_number", 16)}
                    {renderField("Bank Address", formData.bank?.address, "bank.address", 32)}
                    {renderField("Routing Number", formData.bank?.routing_number, "bank.routing_number", 12)}
                    {renderField("IBAN", formData.bank?.iban, "bank.iban", 40)}
                    {renderField("Branch Number", formData.bank?.branch_number, "bank.branch_number", 8)}
                    {renderField("City", formData.bank?.city, "bank.city", 32)}
                    {renderField("State", formData.bank?.state, "bank.state", 32)}
                    {renderField("Postal Code", formData.bank?.postal_code, "bank.postal_code", 16)}
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                      <Typography variant="h5" gutterBottom>
                        Owner Details
                      </Typography>
                    </Box>
                    <Divider />
                  </Grid>
                  {formData.owner?.map((owner, index) => (
                    <Grid item xs={12} key={index}>
                      <Card elevation={3} sx={{ padding: 3 }}>
                        <CardContent>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                              <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">Owner {index + 1}</Typography>
                              </Box>
                              <Divider sx={{ marginY: 1 }} />
                            </Grid>
                            {renderField("First Name", owner.first_name, `owner.${index}.first_name`, 16)}
                            {renderField("Last Name", owner.last_name, `owner.${index}.last_name`, 16)}
                            {renderField("Middle Name", owner.middle_name, `owner.${index}.middle_name`, 16)}
                            {renderField("ID", owner.id, `owner.${index}.id`, 16)}
                            {renderField("Taxpayer ID", owner.taxpayer_id, `owner.${index}.taxpayer_id`, 16)}
                            {renderField("Percentage", owner.percentage, `owner.${index}.percentage`, 3)}
                            {renderField("ID Issuer", owner.id_issuer, `owner.${index}.id_issuer`, 16)}
                            {renderField("Personal Email", owner.personal_email, `owner.${index}.personal_email`, 16)}
                            {renderField("Personal Phone", owner.personal_phone, `owner.${index}.personal_phone`, 16)}
                            {renderField("Address", owner.address, `owner.${index}.address`, 32)}
                            {renderField("City", owner.city, `owner.${index}.city`, 16)}
                            {renderField("State", owner.state, `owner.${index}.state`, 16)}
                            {renderField("Country", owner.country, `owner.${index}.country`, 2)}
                            {renderField("Postal Code", owner.postal_code, `owner.${index}.postal_code`, 16)}
                            {renderField("Neighborhood", owner.neighborhood, `owner.${index}.neighborhood`, 32)}
                            {renderField("Percentage", owner.percentage, `owner.${index}.percentage`, 32)}
                            {renderField("Residency Time", owner.residency_time, `owner.${index}.residency_time`, 4)}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
            <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
              {!disabled && (
                <Button variant="contained" color="primary" onClick={handleSave}>
                  <SaveIcon />
                </Button>
              )}
            </Box>
          </Paper>
        </>
      )}
  
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <Typography>Terminal has been successfully updated!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );  
};

TerminalDetails.propTypes = {
  terminalDetails: PropTypes.object.isRequired,
};

export default TerminalDetails;
