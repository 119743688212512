import React, { useState, useContext } from "react";
import {
  Button,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AuthContext from "../../context/AuthContext";
import { fetchData } from "../../services/apiService";

const TransactionDailyReportForm = ({
  setSnackOpen,
  setMessageOpen,
  setSeverity,
}) => {
  const { token, acquirerData } = useContext(AuthContext);
  const [currency, setCurrency] = useState(acquirerData?.currency_code || "");
  const [isoID, setIsoID] = useState(acquirerData?.isoID || "");
  const [acquirerID, setAcquirerID] = useState(acquirerData?.acquirerId || null);
  const [reportDate, setReportDate] = useState(null);
  const [hide, setHide] = useState(true);
  const [isoData, setIsoData] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  const minDate = () => "2022-01-01";
  const maxDate = () => "2025-12-31";

  const handleDateChange = (event) => {
    setReportDate(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const timestamp = new Date().toISOString();
    const data = {
      version: "04.09.01",
      ...(acquirerID && { acquirerID }),
      ...(isoID && { isoID }),
      callbackURL: "https://webhook.site/dd4eadec-dab5-4397-9754-c45738f2fb9e",
      fiat: {
        currencyCode: currency,
      },
      report: {
        date: reportDate.replaceAll("-", ""),
      },
      timeStamp: timestamp,
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      acquirerName: acquirerData.acquirerName,
      acquirerID: acquirerData.acquirerId,
    };

    try {
      const response = await fetchData({
        endpoint: "/transactions-report",
        data,
        headers,
        token,
      });

      if (response && response.data) {
        setIsoData(response.data.iso || []);
        setHide(false);
        setSnackOpen(true);
        setMessageOpen("Report generated successfully!");
        setSeverity("success");
      }
    } catch (error) {
      setSnackOpen(true);
      setMessageOpen("No transaction for this date");
      setSeverity("error");
    }
  };

  const downloadCSV = () => {
    const headers = [
      "ISO",
      "Holding ID",
      "Holding Name",
      "Group ID",
      "Group Name",
      "Merchant ID",
      "Merchant Name",
      "Store",
      "Transaction ID",
      "Authorization ID",
      "Transaction Amount",
      "Transaction Status",
      "Exchange ID",
      "Terminal ID",
      "Date",
      "Time",
    ];
  
    const rows = [];

    isoData.forEach((iso) => {
  
      (iso.holding || []).forEach((holding) => {
        const holdingID = holding.holdingID || "";
        const holdingName = holding.holdingName || "";
  
        (holding.groups || []).forEach((group) => {
          const groupID = group.groupID || "";
          const groupName = group.groupName || "";
  
          (group.merchants || []).forEach((merchant) => {
            const merchantID = merchant.ID || "";
            const merchantName = merchant.name || "";
            const store = merchant.store || "";
  
            (merchant.transactions || []).forEach((transaction) => {
              const txID = transaction.txID || "";
              const authorizationID = transaction.authorizationID || "";
              const txAmount = transaction.txAmount || "";
              const txStatus = transaction.txStatus || "";
              const exchangeID = transaction.exchangeID || "";
              const terminalID = transaction.terminalID || "";
              const date = transaction.date || "";
              const time = transaction.time || "";
  
              rows.push([
                isoID,
                holdingID,
                holdingName,
                groupID,
                groupName,
                merchantID,
                merchantName,
                store,
                txID,
                authorizationID,
                txAmount,
                txStatus,
                exchangeID,
                terminalID,
                date,
                time,
              ]);
            });
  
            if (!(merchant.transactions || []).length) {
              rows.push([
                isoID,
                holdingID,
                holdingName,
                groupID,
                groupName,
                merchantID,
                merchantName,
                store,
                "",
                "",
                "",
                "",
                "",
              ]);
            }
          });
  
          if (!(group.merchants || []).length) {
            rows.push([
              isoID,
              holdingID,
              holdingName,
              groupID,
              groupName,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ]);
          }
        });
  
        if (!(holding.groups || []).length) {
          rows.push([
            isoID,
            holdingID,
            holdingName,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ]);
        }
      });
    });
  
    const csvContent = [headers, ...rows]
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");
  
    // Criando e baixando o arquivo CSV
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "transaction_daily_report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function formatTime(time) {
    if (!time || typeof time !== "string") {
      return "Invalid Time"; 
    }
    try {
      const [hours, minutes, seconds] = time.split(":");
      return `${hours}:${minutes}:${seconds.split(".")[0]}`; 
    } catch (error) {
      console.error("Error formatting time:", error);
      return "Invalid Time"; 
    }
  }
  
  const renderTransactionsTable = (transactions) => (
    <TableContainer component={Paper} style={{ marginTop: "10px" }}>
      <Typography variant="subtitle2" style={{ marginBottom: "10px" }}>
        Transactions
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">Transaction ID</TableCell>
            <TableCell align="center">Authorization ID</TableCell>
            <TableCell align="center">Exchange ID</TableCell>
            <TableCell align="center">Terminal ID</TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell align="center">{transaction.txID}</TableCell>
              <TableCell align="center">{transaction.authorizationID}</TableCell>
              <TableCell align="center">{transaction.exchangeID}</TableCell>
              <TableCell align="center">{transaction.terminalID}</TableCell>
              <TableCell align="center">
                {Number(transaction.txAmount).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </TableCell>
              <TableCell align="center">{transaction.txStatus}</TableCell>
              <TableCell align="center">{transaction.date}</TableCell>
              <TableCell align="center">{formatTime(transaction.time)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderMerchantsTable = (merchants) =>
    merchants.map((merchant, index) => (
      <Accordion key={index} style={{ marginTop: "10px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            {merchant.name} - Store: {merchant.store}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {merchant.transactions && renderTransactionsTable(merchant.transactions)}
        </AccordionDetails>
      </Accordion>
    ));

  const renderGroupsTable = (groups) =>
    groups.map((group, index) => (
      <Accordion key={index} style={{ marginTop: "10px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            {group.groupName} - Group ID: {group.groupID}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {group.merchants && renderMerchantsTable(group.merchants)}
        </AccordionDetails>
      </Accordion>
    ));

  const renderHoldingsTable = (holdings) =>
    holdings.map((holding, index) => (
      <Accordion key={index} style={{ marginTop: "10px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            {holding.holdingName} - Holding ID: {holding.holdingID}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {holding.groups && renderGroupsTable(holding.groups)}
        </AccordionDetails>
      </Accordion>
    ));

  return (
    <>
      <form onSubmit={handleSubmit} style={{ margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="acquirer-id"
              label="Acquirer ID"
              value={acquirerID || ""}
              onChange={(event) => setAcquirerID(event.target.value)}
              disabled 
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="iso-id"
              label="ISO ID"
              value={isoID || ""}
              onChange={(event) => setIsoID(event.target.value)}
              disabled 
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth disabled> 
              <InputLabel id="currency-label">Currency Code</InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                value={currency || ""}
                label="Currency Code"
                onChange={(event) => setCurrency(event.target.value)}
                required
              >
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="BRL">BRL</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="report-date"
              label="Report Date"
              type="date"
              value={reportDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: minDate(),
                max: maxDate(),
              }}
              required
            />
          </Grid>
          <Grid item xs={12} textAlign={isMobile ? "center" : "left"} style={{ marginTop: "20px" }}>
            <Button variant="contained" type="submit" size="large">
              GENERATE
            </Button>
            {isoData.length > 0 && (
              <Button
                variant="outlined"
                style={{ marginLeft: "10px" }}
                onClick={downloadCSV}
              >
                Download CSV
              </Button>
            )}
          </Grid>
        </Grid>
      </form>

      {isoData.map((iso, index) => (
        <Accordion key={index} style={{ marginTop: "20px" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">ISO Data: {iso.isoID}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderHoldingsTable(iso.holding)}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default TransactionDailyReportForm;
